import React from 'react';
import { Text, Flex } from 'rebass/styled-components';

import Section from 'src/components/Section';

const YEAR = new Date().getFullYear();

const Contact = () => {
  return (
    <Section name="footer">
      <Flex alignItems="center" justifyContent="center" p={2} sx={{ backgroundColor: 'grey1' }}>
        <Text>Paisios Constructions © {YEAR} All rights reserved</Text>
      </Flex>
    </Section>
  );
};

export default Contact;
