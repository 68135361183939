import React from 'react';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'gatsby';
import { Box } from 'rebass/styled-components';
import { useIntl } from 'gatsby-plugin-intl';
import { useLocation } from '@reach/router';

const ScrollLinkAnimated = styled(ScrollLink)`
  text-decoration: none;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: inherit;
  text-transform: uppercase;
  transition: 0.4s;
  cursor: pointer;
  white-space: nowrap;
  &.active {
    border-bottom: ${props => `5px solid ${props.theme.colors.primaryLight}`};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    bottom: -5px;
    background: ${props => props.theme.colors.secondaryLight};
    height: 5px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:after {
    left: 0;
    right: auto;
    width: 100%;
  }
`;

const LinkAnimated = styled(Link)`
  text-decoration: none;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 5px;
  color: inherit;
  text-transform: uppercase;
  transition: 0.4s;
  cursor: pointer;
  white-space: nowrap;
  :active {
    border-bottom: ${props => `5px solid ${props.theme.colors.primaryLight}`};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 0;
    bottom: -5px;
    background: ${props => props.theme.colors.secondaryLight};
    height: 5px;
    transition-property: width;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }

  &:hover:after {
    left: 0;
    right: auto;
    width: 100%;
  }
`;

const HeaderLink = ({ scrollTo, linkTo, page, onClick }) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  const includesLanguage = pathname.includes('/en') || pathname.includes('/el');

  let language;
  let pathWithoutLanguage;

  if (includesLanguage) {
    const pathParts = pathname.split('/');
    language = pathParts.splice(0, 2).join('');
    pathWithoutLanguage = pathParts.join('/') || 'main';
  } else {
    pathWithoutLanguage = pathname.substr(pathname.lastIndexOf('/'), pathname.length);
  }

  const isCurrentPage = pathWithoutLanguage === page;
  const label = intl.formatMessage({ id: `${scrollTo || linkTo}.menuItem` });
  const linkToWithLanguage = includesLanguage ? `/${language}/${linkTo}` : linkTo;

  return (
    <Box mx={['auto', 2]} my={[2, 0]} fontSize={[4, 2]}>
      {!isCurrentPage && (
        <LinkAnimated to={linkToWithLanguage} onClick={onClick}>
          {label}
        </LinkAnimated>
      )}
      {isCurrentPage && (
        <ScrollLinkAnimated to={scrollTo || linkTo} smooth spy onClick={onClick}>
          {label}
        </ScrollLinkAnimated>
      )}
    </Box>
  );
};

export default HeaderLink;
