import React from 'react';
import { Flex } from 'rebass/styled-components';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';

import LinkAnimated from 'src/components/LinkAnimated';

const languageName = {
  en: 'EN',
  el: 'ΕΛ',
};

const LanguageSwitch = () => (
  <IntlContextConsumer>
    {({ languages, language: currentLocale }) =>
      languages.map(
        language =>
          currentLocale !== language && (
            <Flex key={language} mx={['auto', 2]} my={[2, 0]} fontSize={[4, 2]}>
              <LinkAnimated onClick={() => changeLocale(language)}>{languageName[language]}</LinkAnimated>
            </Flex>
          ),
      )
    }
  </IntlContextConsumer>
);

export default LanguageSwitch;
