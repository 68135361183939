import React from 'react';
import { Card } from 'rebass';
import Img from 'gatsby-image/withIEPolyfill';
import { Flex, Text } from 'rebass/styled-components';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { navigate } from 'gatsby-plugin-intl';

const cardStyles = {
  cursor: 'pointer',
  boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
  justifySelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  transition: 'filter 150ms ease-in-out',
  // borderRadius: '15px',
  overflow: 'hidden',
  margin: '15px !important',
  '&:hover': {
    filter: 'brightness(90%)',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
  },
  width: [`calc(100% - 30px)`, `calc(50% - 30px)`],
};

const ShowCaseActionContainer = ({ children, buildingComplex }) => {
  if (buildingComplex) {
    return (
      <Card sx={cardStyles} onClick={() => navigate(`/projects/complex/${buildingComplex}`)}>
        {children}
      </Card>
    );
  }

  const { openLightbox } = useLightbox();

  return (
    <Card sx={cardStyles} onClick={() => openLightbox()}>
      {children}
    </Card>
  );
};

export default ({ cardImage, projectImages, headingText, sx, buildingComplex }) => {
  const SRLOptions = {
    buttons: {
      showDownloadButton: false,
      showThumbnailsButton: false,
    },
  };

  return (
    <SimpleReactLightbox>
      <ShowCaseActionContainer
        cardImage={cardImage}
        projectImages={projectImages}
        headingText={headingText}
        buildingComplex={buildingComplex}
      >
        <Img fluid={cardImage} alt={headingText} style={{ height: '150px' }} imgStyle={{ objectFit: 'cover' }} />
        <SRLWrapper images={projectImages} options={SRLOptions} />
        <Flex
          p={2}
          fontSize={2}
          color="primary"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          textAlign="center"
          sx={{ backgroundColor: 'grey1' }}
        >
          {headingText}
        </Flex>
      </ShowCaseActionContainer>
    </SimpleReactLightbox>
  );
};
