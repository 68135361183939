import React, { useState } from 'react';
import Headroom from 'react-headroom';
import { Flex, Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Fade } from 'react-reveal';
import { useLocation } from '@reach/router';

import Scroll from 'react-scroll';
import Hamburger from 'hamburger-react';

import HeaderLink from 'src/components/HeaderLink';
import LanguageSwitch from 'src/components/LanguageSwitch';
import Logo from 'src/assets/images/logos/logo.svg';

const HeaderContainer = styled(Headroom)`
  .headroom {
    background: white;
    border-bottom: 1px solid ${props => props.theme.colors.primaryDark};
    top: 0;
    left: 0;
    right: 0;
    transition: transform 200ms ease-in-out;
    z-index: 998;
  }
  .headroom--unfixed {
    position: fixed;
    transform: translateY(0);
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
  z-index: 9;
  max-height: 0px;
`;

const StyledMobileMenu = styled.div`
  position: fixed;
  background-color: white;
  z-index: 8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 112px;
  transition: transform 150ms ease-in-out;
  transform: ${props => (props.toggled ? 'translateX(0)' : 'translateX(-100vw)')};
`;

const StyledHamburger = styled(Hamburger)`
  position: fixed;
  top: 0;
  z-index: 997;
`;

const MobileMenu = ({ toggled, toggle }) => {
  return (
    <StyledMobileMenu toggled={toggled}>
      <Flex alignItems="center" justifyContent="center" pt={4} flexDirection="column">
        <MenuEntries toggle={toggle} />
      </Flex>
    </StyledMobileMenu>
  );
};

const MenuEntries = ({ toggle }) => {
  let closeMenu = () => {};

  if (toggle) {
    closeMenu = () => {
      toggle(false);
    };
  }
  return (
    <>
      <HeaderLink scrollTo="aboutUs" linkTo="#aboutUs" page="main" onClick={closeMenu} />
      <HeaderLink scrollTo="services" linkTo="#services" page="main" onClick={closeMenu} />
      <HeaderLink linkTo="projects" page="projects" onClick={closeMenu} />
      <HeaderLink scrollTo="contact" linkTo="#contact" page="main" onClick={closeMenu} />
      <Box
        mx={['auto', 2]}
        my={[3, 1]}
        sx={{ backgroundColor: 'black', width: ['100px', '2px'], height: ['2px', '18px'] }}
      >
        &nbsp;
      </Box>
      <LanguageSwitch />
    </>
  );
};

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const { pathname } = useLocation();

  const pathParts = pathname.split('/');
  let language = pathParts.splice(0, 2).join('');

  if (language !== 'en' && language !== 'el') {
    pathParts.push(language);
    language = 'el';
  }

  const pathWithoutLanguage = pathParts.join('/') || 'main';

  const isMainPage = pathWithoutLanguage === 'main';

  return (
    <>
      <HeaderContainer disableInlineStyles disable={isOpen}>
        <Fade>
          <Flex flexWrap="no-wrap" justifyContent="space-between" alignItems="center" p={3}>
            {isMainPage && (
              <Scroll.Link to="home" smooth onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
                <Image src={Logo} width="240px" alt="Paisios Constructions Logo" />
              </Scroll.Link>
            )}
            {!isMainPage && (
              <Link to={`/${language}/`} onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
                <Image src={Logo} width="240px" alt="Paisios Constructions Logo" />
              </Link>
            )}
            <Flex mr={[0, 3]} sx={{ display: ['none !important', 'none !important', 'flex !important'] }}>
              <MenuEntries />
            </Flex>
            <Box display={['block', 'block', 'none']}>
              <StyledHamburger toggled={isOpen} toggle={setOpen} />
            </Box>
          </Flex>
        </Fade>
      </HeaderContainer>
      <MobileMenu toggled={isOpen} toggle={setOpen}></MobileMenu>
    </>
  );
};

export default Header;
